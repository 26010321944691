import React, {Component} from 'react';
import NavBar from '../components/NavBar';
import FeatureIcon from '../components/FeatureIcon';
import TestimonialSlider from '../components/TestimonialSlider';
import BrandLogoSlider from '../components/BrandLogoSlider';
import Footer from '../components/Footer';
import MobileMenu from '../components/MobileMenu';
import ModalVideo from 'react-modal-video'
import { Link } from 'react-router-dom';
import videoThumbnail from "../assets/images/video-thumbnail.png"
import video from "../assets/videos/Liveeasy.mp4"
class About extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }

    render(){
        return(
            <div>

                {/* Navigation bar */}
                <NavBar/>
                
                {/* breadcrumb */}
                {/*====================  breadcrumb area ====================*/}
                <div className="breadcrumb-area breadcrumb-bg">
                    <div className="container">
                        <div className="row">
                            <div className="col">
                                <div className="page-banner text-center">
                                    <h1>About Us</h1>
                                    <ul className="page-breadcrumb">
                                        <li><Link to="/">Home</Link></li>
                                        <li>About Us</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of breadcrumb area  ====================*/}

                <div className="page-wrapper section-space--inner--top--120">
                {/*About section start*/}
                <div className="about-section section-space--inner--bottom--120">
                <div className="container">
                    <div className="row row-25 align-items-center">
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-image-two">
                        <img src={videoThumbnail} alt="" />
                            <span className="video-popup">
                                <ModalVideo channel='custom' isOpen={this.state.isOpen} url={video} onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <i className="ion-ios-play-outline" />
                                </button>
                            </span>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 mb-30">
                        <div className="about-content-two">
                        <h3>Welcome to Live Easy</h3>
                        <h1>Your One-Stop Solution for HOA Community Management</h1>
                        {/* <h4>Liveeasy is a software property and HOA Community Management software business.</h4> */}
                        <p>At Liveeasy, we understand that managing modern apartments requires a delicate balance of personalized service and advanced technology. Our innovative Homeowners Association (HOA) Community Management software is designed to cater specifically to the needs of modern apartments, offering seamless solutions for estate management, maintenance requests, community security, community administration, and document management.</p>
                        <p>With Liveeasy, you can streamline your operations, enhance communication, and ensure a seamless living experience for your residents.</p>
                        <Link to="/contact-us" className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">Contact Us</Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Feature Icon */}
                <FeatureIcon background = "grey-bg"/>

                {/*About section start*/}
                <div className="about-section section-space--inner--120">
                <div className="container">
                    <div className="about-wrapper row">
                    <div className="col-sm-6 col-12 order-1 order-lg-2">
                        <div className="about-image about-image-1">
                        <img src="assets/img/about/aboutb.png" alt="" />
                        </div>
                    </div>
                    <div className="col-sm-6 col-12 order-2 order-lg-3">
                        <div className="about-image about-image-2">
                        <img src="assets/img/about/abouta.png" alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-3 order-lg-1">
                        <div className="about-content about-content-1">
                        <h1>Housing With Tech</h1>
                        <p>At Liveeasy, we are committed to delivering personalized service and cutting-edge technology to meet the unique needs of your HOA community. Our software and services are designed to save you time, improve efficiency, and enhance the overall resident experience. Experience the Liveeasy difference today and unlock the full potential of your community management efforts.</p>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12 order-4">
                        <div className="about-content about-content-2">
                        <p>We just launched a cutting-edge mobile app that revolutionizes how residents can submit maintenance requests. With a few taps on their smartphones, residents can easily report issues, attach images for better clarification, and receive real-time updates on the status of their requests. The app streamlines the entire maintenance process, enhancing resident satisfaction and making community managers' jobs more efficient.</p>
                        <p>Liveeasy's comprehensive tools enable HOAs and community managers to handle administrative tasks effortlessly. From maintenance request, tracking community events and announcements to managing vendor contracts and communications, the software streamlines administrative duties, allowing managers to focus on community growth.</p>
                        <a href={`${process.env.PUBLIC_URL}/product`} className="ht-btn--default ht-btn--default--dark-hover section-space--top--20">View Product Plan</a>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*About section end*/}
                
                {/* Fun fact */}
                {/* <Funfact/> */}
                
                {/* Team member */}
                {/* <TeamMemberGrid/> */}
                
                {/* Testimonial Slider */}
                <TestimonialSlider/>
                
                {/* Brand logo */}
                <BrandLogoSlider background = "" />

                </div>


                {/* Footer */}
                <Footer/>

                {/* Mobile Menu */}
                <MobileMenu/>

            </div>
        )
    }
}


export default About;