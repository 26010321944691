import React, {Component} from 'react';
import { Link } from 'react-router-dom';
import logo from "../assets/images/logo.png"

class Footer extends Component{
    render(){
        return(
            <div>
                {/*====================  footer area ====================*/}
                <div className="footer-area dark-bg">
                    <div className="container">
                        <div className="row">
                        <div className="col-lg-12">
                            <div className="footer-content-wrapper section-space--inner--100">
                            <div className="row">
                                <div className="col-xl-3 col-lg-3 col-md-12">
                                {/* footer intro wrapper */}
                                <div className="footer-intro-wrapper">
                                    <div className="footer-logo">
                                    <Link to="/">
                                        <img src={logo} className="img-fluid" alt="logo" style={{height: "50px"}} />
                                    </Link>
                                    </div>
                                    <div className="footer-desc">
                                    From management to accounting, we can help you manage your community efficiently and effectively.
                                    </div>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget">
                                    <h4 className="footer-widget__title">USEFUL LINKS</h4>
                                    <ul className="footer-widget__navigation">
                                    <li><Link to="/">Home</Link></li>
                                    <li><Link to="/about-us">About Us</Link></li>
                                    <li><Link to="/product">Product</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><Link to="/privacy-policy">Privacy Policy</Link></li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget">
                                    <h4 className="footer-widget__title">Features</h4>
                                    <ul className="footer-widget__navigation">
                                    <li><Link to="/">Resident Management</Link></li>
                                    <li><Link to="/">Ticket & Support</Link></li>
                                    <li><Link to="/">Communications</Link></li>
                                    <li><Link to="/">Community Forum</Link></li>
                                    <li><Link to="/">Estate Administration</Link></li>
                                    </ul>
                                </div>
                                </div>
                                <div className="col-xl-2 offset-xl-1 col-lg-3 col-md-4">
                                {/* footer widget */}
                                <div className="footer-widget mb-0">
                                    <h4 className="footer-widget__title">CONTACT US</h4>
                                    <div className="footer-widget__content">
                                    <p className="address">3927 Jamieson ave, unit 1w, Saint Louis Missouri</p>
                                    <ul className="contact-details">
                                        <li>+1 860 328 3251</li>
                                        <li>+234 908 707 621</li>
                                        <li>support@jjaogroup.com</li>
                                    </ul>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                        <div className="footer-copyright-wrapper">
                        <div className="footer-copyright text-center">
                        Copyright © {(new Date()).getFullYear()}. All right reserved
                        </div>
                    </div>
                </div>
                {/*====================  End of footer area  ====================*/}
                {/*====================  scroll top ====================*/}
                <button className="scroll-top" id="scroll-top">
                    <i className="ion-android-arrow-up" />
                </button>
                {/*====================  End of scroll top  ====================*/}
            </div>
        )
    }
}


export default Footer;