import React, { Component } from "react";

class MobileMenu extends Component {
  render() {
    return (
      <div>
        {/*=======  offcanvas mobile menu  =======*/}
        <div className="offcanvas-mobile-menu" id="mobile-menu-overlay">
          <a
            href="#/"
            className="offcanvas-menu-close"
            id="mobile-menu-close-trigger"
          >
            <i className="ion-android-close" />
          </a>
          <div className="offcanvas-wrapper">
            <div className="offcanvas-inner-content">
              {/* <div className="offcanvas-mobile-search-area">
                <form action="#">
                  <input type="search" placeholder="Search ..." />
                  <button type="submit">
                    <i className="fa fa-search" />
                  </button>
                </form>
              </div> */}
              <nav className="offcanvas-navigation">
                <ul>
                  <li>
                    <a href="/">HOME</a>
                  </li>
                  <li>
                    <a href="/about-us">ABOUT US</a>
                  </li>
                  <li>
                    <a href="/product">PRODUCT</a>
                  </li>
                  <li>
                    <a href="/contact-us">CONTACT US</a>{" "}
                  </li>
                </ul>
              </nav>
              <div className="offcanvas-widget-area">
                <div className="off-canvas-contact-widget">
                  <div className="header-contact-info">
                    <ul className="header-contact-info__list">
                      <li>
                        <i className="ion-android-phone-portrait" />{" "}
                        <a href="tel://18603283251">+1 860 328 3251</a>
                      </li>
                      <li>
                        <i className="ion-android-mail" />{" "}
                        <a href="mailto:support@jjaogroup.com">
                        support@jjaogroup.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/*Off Canvas Widget Social Start*/}
                {/* <div className="off-canvas-widget-social">
                  <a href="#/" title="Facebook">
                    <i className="fa fa-facebook" />
                  </a>
                  <a href="#/" title="Twitter">
                    <i className="fa fa-twitter" />
                  </a>
                  <a href="#/" title="LinkedIn">
                    <i className="fa fa-linkedin" />
                  </a>
                  <a href="#/" title="Youtube">
                    <i className="fa fa-youtube-play" />
                  </a>
                  <a href="#/" title="Vimeo">
                    <i className="fa fa-vimeo-square" />
                  </a>
                </div> */}
                {/*Off Canvas Widget Social End*/}
              </div>
            </div>
          </div>
        </div>
        {/*=======  End of offcanvas mobile menu  =======*/}
      </div>
    );
  }
}

export default MobileMenu;
