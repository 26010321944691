import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import dev from "../assets/images/dev.jpg"
class ServiceTabExample extends Component{

    render(){
        
        /* service tab menu */
        let serviceTabMenuData = [
            {iconName: 'flaticon-002-welding', tabMenuName: 'Estate Management'},
            {iconName: 'flaticon-004-walkie-talkie', tabMenuName: 'Community Security'},
            {iconName: 'flaticon-015-cart', tabMenuName: 'Administration'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'Resident Portal'},
            {iconName: 'flaticon-010-tank-1', tabMenuName: 'Document Management'}
        ];

        let serviceTabMenuDatalist = serviceTabMenuData.map((val, i)=>{
            return(
                <Tab key={i}>  <span className="icon"><i className={val.iconName} /></span> <span className="text">{val.tabMenuName}</span></Tab>
            )
        });

        
        /* service tab content */
        
        let serviceTabContentData = [
            {bgUrl: "est-mgt.jpg", contentTitle: 'Estate Management', contentDesc: 'Efficient estate management is at the heart of a well-functioning HOA community. Liveeasy provides you with robust tools to simplify and automate essential tasks. Our software empowers you to stay on top of every aspect of estate management. Say goodbye to paperwork and excel sheets – with Liveeasy, you\'ll have all the information you need at your fingertips.', serviceLink: '/'},
            {bgUrl: "est-sec.jpg", contentTitle: 'Community Security', contentDesc: 'Creating a safe and secure living environment is a top priority for any HOA community. With Liveeasy\'s community security features, you can ensure the well-being of your residents. Our software includes visitor management systems, access control solutions, and incident reporting tools. Monitor entry and exit points, track visitor activity, and receive real-time alerts to maintain a vigilant and secure community.', serviceLink: '/'},
            {bgUrl: "com-admin.jpg", contentTitle: 'Community Administration', contentDesc: 'Efficient community administration is key to the smooth functioning of an HOA. Liveeasy offers a range of administrative tools to simplify your day-to-day tasks. Our software enables you to manage resident profiles, and facilitate transparent communication with community members. With Liveeasy, you can streamline administrative processes, save time, and ensure accountability.', serviceLink: '/'},
            {bgUrl: "resident-mgt.jpg", contentTitle: 'Resident Portal', contentDesc: 'Enhance resident satisfaction with a dedicated portal where they can access important community information, submit maintenance requests, make payments, and engage in community discussions.', serviceLink: '/'},
            {bgUrl: "doc-mgt.jpg", contentTitle: 'Document Management', contentDesc: 'Say goodbye to paper clutter and lost documents. Liveeasy offers a centralized document management system where you can securely store, organize, and access important community files.', serviceLink: '/'}
        ];

        let serviceTabContentDatalist = serviceTabContentData.map((val, i)=>{
            return(
                <TabPanel key={i}>
                    <div className="service-tab__single-content-wrapper" style={{ backgroundImage: `url(${dev})` }}>
                        <div className="service-tab__single-content">
                            <h3 className="service-tab__title">{val.contentTitle}</h3>
                            <p className="service-tab__text">{val.contentDesc}</p>
                            {/* <a href={`${process.env.PUBLIC_URL}/${val.serviceLink}`} className="see-more-link">SEE MORE</a> */}
                        </div>
                    </div>
                </TabPanel>
            )
        });

        return(
            <div>

                {/*====================  service tab area ====================*/}
                <div className="service-tab-area section-space--inner--40">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-12">
                        <div className="section-title-area text-center">
                        <h2 className="section-title section-space--bottom--50">Product Features</h2>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        {/* service tab wrapper */}
                        
                        <div className="service-tab-wrapper">
                        <Tabs>
                            <div className="row no-gutters">
                                <div className="col-md-4">
                                    <TabList>
                                        <div className="service-tab__link-wrapper">
                                            {serviceTabMenuDatalist}
                                        </div>
                                    </TabList>
                                </div>

                                <div className="col-md-8">
                                    {serviceTabContentDatalist}
                                </div>
                            </div>
                        </Tabs>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of service tab area  ====================*/}
            </div>
        )
    }
}

export default ServiceTabExample;