import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'
import {Link} from "react-router-dom"
import videoThumbnail from "../assets/images/video-thumbnail.png"
import video from "../assets/videos/Liveeasy.mp4"

class VideoCta extends Component{

    constructor () {
        super()
        this.state = {
          isOpen: false
        }
        this.openModal = this.openModal.bind(this)
      }
     
      openModal () {
        this.setState({isOpen: true})
      }
    render(){
        return(
            <div>
                {/*====================  video cta area ====================*/}
                <div className="video-cta-area section-space--inner--120">
                    <div className="container">
                        <div className="row align-items-center">
                        <div className="col-lg-6 col-md-6">
                            <div className="video-cta-content">
                            <h4 className="video-cta-content__small-title">ABOUT US</h4>
                            <h5 className="video-cta-content__title">Your One-Stop Solution for HOA Community Management</h5>
                            <p className="video-cta-content__text">At Liveeasy, we understand that managing modern apartments requires a delicate balance of personalized service and advanced technology. Our innovative Homeowners Association (HOA) Community Management software is designed to cater specifically to the needs of modern apartments, offering seamless solutions for estate management, maintenance requests, community security, community administration, and document management.</p>
                            <Link to="/about-us" className='ht-btn--default ht-btn--default--dark-hover section-space--top--20'>Learn more</Link>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-1 col-md-6">
                            <div className="cta-video-image">
                            <div className="video-popup">
                            <ModalVideo channel='custom' isOpen={this.state.isOpen} url={video} onClose={() => this.setState({isOpen: false})} />
                                <button onClick={this.openModal}>
                                    <div className="cta-video-image__image">
                                        <img src={videoThumbnail} className="img-fluid" alt="" />
                                    </div>
                                    <div className="cta-video-image__icon">
                                        <i className="ion-ios-play" />
                                    </div>
                                </button>
                            </div>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
                {/*====================  End of video cta area  ====================*/}
            </div>
        )
    }
}


export default VideoCta;