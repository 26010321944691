import React, {Component} from 'react';
import Swiper from 'react-id-swiper';
import { Link } from 'react-router-dom';

class HeroSliderOne extends Component{
    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            effect: 'fade',
            navigation: {
                nextEl: '.ht-swiper-button-next',
                prevEl: '.ht-swiper-button-prev'
            },
            renderPrevButton: () => (
                <div className="ht-swiper-button-prev ht-swiper-button-nav d-none d-xl-block"><i className="ion-ios-arrow-left" /></div>
              ),
              renderNextButton: () => (
                <div className="ht-swiper-button-next ht-swiper-button-nav d-none d-xl-block"><i className="ion-ios-arrow-forward" /></div>
              ),
            autoplay: {
                delay: 5000,
            }
        }

        let data = [
            // {bgImg: '01.jpg', sliderTitle: '', sliderSubtitle: '', btnLink: ''},
            {bgImg: '01.jpg', sliderTitle: 'Effortless Community Management Solutions', sliderSubtitle: 'Streamline operations, enhance security, and foster a vibrant community with Liveeasy\'s comprehensive software property and HOA Community Management platform.', btnLink:"/about-us", btnTitile: "Learn more"},
            {bgImg: '2.jpg', sliderTitle: 'Empower Your Community', sliderSubtitle: "Experience community management solutions designed specifically for HOAs and residential complexes. Liveeasy understands the unique needs of communities.", btnLink:"/product", btnTitile:"Choose the Perfect Plan"},
            {bgImg: '3.jpg', sliderTitle: 'Hassle-Free Community Management', sliderSubtitle: "From garbage clearance to property inspections, Liveeasy provides the tools and technology to create a vibrant and well-managed community.", btnLink:"/contact-us", btnTitile:"Contact Us"}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide" key={i}>
                    <div className="hero-slider__single-item" style={{ backgroundImage: `url(assets/img/slider/${val.bgImg})` }}>
                        <div className="hero-slider__content-wrapper">
                            <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                <div className="hero-slider__content m-auto text-center">
                                    <h2 className="hero-slider__title" style={{zIndex: 3}}>{val.sliderTitle}</h2>
                                    <p className="hero-slider__text" style={{zIndex: 3}}>{val.sliderSubtitle}</p>
                                    {val.btnLink && (
                                          <Link style={{zIndex: 3}} className="hero-slider__btn hero-slider__btn--style2" to="/">{val.btnTitile}</Link>
                                    )}
                                  
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        });

        return(
            <div>
                {/*====================  hero slider area ====================*/}
                <div className="hero-alider-area">
                    <Swiper {...params}>
                        {DataList}
                    </Swiper>
                </div>
                {/*====================  End of hero slider area  ====================*/}

            </div>
        )
    }
}

export default HeroSliderOne;