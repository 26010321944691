import React, { useState } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import axios from "axios";
import { Link } from "react-router-dom";

const Contact = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [resMsg, setResMsg] = useState("");
//   const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitContact = async(e) => {
    e.preventDefault();
    setIsLoading(true);
    axios.defaults.headers.post["Content-Type"] = "application/json";
   await axios
      .post("https://formsubmit.co/ajax/support@jjaogroup.com", {
        name: name,
        email: email,
        Message: msg,
      })
      .then((response) => {
        setIsLoading(false);
        if (response.status === 200) {
          setResMsg("Thank you for contacting us.");
          setName("")
          setEmail("")
          setMsg("")
        } else {
        //   setIsError(true);
          setIsLoading(false);
          setResMsg("Oops!!!, something went wrong. Please try again");
        }
      })
      .catch((error) => console.log(error));
    setIsLoading(false);
  };
  return (
    <div>
      {/* Navigation bar */}
      <NavBar />

      {/* breadcrumb */}
      {/*====================  breadcrumb area ====================*/}
      <div className="breadcrumb-area breadcrumb-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <div className="page-banner text-center">
                <h1>Contact Us</h1>
                <ul className="page-breadcrumb">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>Contact Us</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*====================  End of breadcrumb area  ====================*/}

      {/*====================  content page content ====================*/}
      <div className="page-wrapper section-space--inner--120">
        {/*Contact section start*/}
        <div className="conact-section">
          <div className="container">
            {/* <div className="row section-space--bottom--50">
                        <div className="col">
                            <div className="contact-map">
                            <iframe title="google-map" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d392436.93004030554!2d-105.13838587646829!3d39.7265847007123!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sbd!4v1558429398879!5m2!1sen!2sbd" allowFullScreen></iframe>
                            </div>
                        </div>
                    </div> */}
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="contact-information">
                  <h3>Contact Information</h3>
                  <ul>
                    <li>
                      <span className="icon">
                        <i className="ion-android-map" />
                      </span>
                      <span className="text">
                        <span>
                          3927 Jamieson ave, unit 1w, Saint Louis Missouri
                        </span>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="ion-ios-telephone-outline" />
                      </span>
                      <span className="text">
                        <a href="tel:+18603283251">+1 860 328 3251</a>
                        <a href="tel:+234908707621">+234 908 707 621</a>
                      </span>
                    </li>
                    <li>
                      <span className="icon">
                        <i className="ion-ios-email-outline" />
                      </span>
                      <span className="text">
                        <a href="mailto:support@jjaogroup.com">
                          support@jjaogroup.com
                        </a>
                        <a href="mailto:admin@liveeasyweb.com">
                          admin@liveeasyweb.com
                        </a>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-8 col-12">
                <div className="contact-form">
                  <h3>Leave Your Message</h3>

                  <p style={{color: "#ffc107"}}><strong>{resMsg}</strong></p><br/>
                  <form onSubmit={submitContact}>
                    <div className="row row-10">
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_name"
                          type="text"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="col-md-6 col-12 section-space--bottom--20">
                        <input
                          name="con_email"
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Your Email"
                          required
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="con_message"
                          placeholder="Your Message"
                          value={msg}
                          onChange={(e) => setMsg(e.target.value)}
                          required
                        />
                      </div>
                      <div className="col-12">
                        <button type="submit" disabled={isLoading}>
                          {isLoading ? "Sending..." : "Send Message"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Contact section end*/}
      </div>
      {/*====================  End of content page content  ====================*/}

      {/* Footer */}
      <Footer />

      {/* Mobile Menu */}
      <MobileMenu />
    </div>
  );
};

export default Contact;
