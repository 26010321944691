import React, {Component} from 'react';
import Swiper from 'react-id-swiper';

class ServiceGridSlider extends Component{
    render(){
        const params = {
            slidesPerView : 3,
            loop: true,
            speed: 1000,
            watchSlidesVisibility: true,
            spaceBetween : 30,
            autoplay: {
                delay: 3000,
            },
            // Responsive breakpoints
            breakpoints: {
                1499:{
                    slidesPerView : 3
                },

                991:{
                    slidesPerView : 2
                },

                767:{
                    slidesPerView : 1
                },

                575:{
                    slidesPerView : 1
                }
            }

        }
        let data = [
            {img: 'Cost-effective.png', serviceTitle: 'Cost-Effective', serviceExcerpt: 'Our plans are competitively priced to offer you the best value for your money', serviceUrl: 'product'},
            {img: 'Scalable-solutions.png', serviceTitle: 'Scalable Solutions', serviceExcerpt: ' As your community grows, you can easily upgrade to a higher plan without any hassle.', serviceUrl: 'product'},
            {img: 'Tailored-features.png', serviceTitle: 'Tailored Features', serviceExcerpt: 'Each plan is specifically designed to address the needs and challenges faced by communities.', serviceUrl: 'product'},
            {img: 'Dedicated-support.png', serviceTitle: 'Dedicated Support', serviceExcerpt: 'Our customer service team is here to assist you at every step, ensuring a seamless experience.', serviceUrl: 'product'},
            // {img: 'service1.jpg', serviceTitle: 'Mobile App for Maintenance Requests', serviceExcerpt: 'Our cutting-edge mobile app revolutionizes how residents can submit maintenance requests. ', serviceUrl: 'product'},
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide" key={i} >
                    <div className="service-grid-item service-grid-item--style2">
                    <div className="service-grid-item__image">
                        <div className="service-grid-item__image-wrapper">
                        <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}>
                            <img src={`assets/img/service/${val.img}`} className="img-fluid" alt="" />
                        </a>
                        </div>
                    </div>
                    <div className="service-grid-item__content">
                        <h3 className="title">
                        <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`}>{val.serviceTitle}</a>
                        </h3>
                        <p className="subtitle">{val.serviceExcerpt}</p>
                        {/* <a href={`${process.env.PUBLIC_URL}/${val.serviceUrl}`} className="see-more-link">SEE MORE</a> */}
                    </div>
                    </div>
                </div>
            )
        });
        return(
            <div style={{marginBottom: "30px"}}>
                {/*====================  project grid slider area ====================*/}
                <div className="service-slider-title-area grey-bg section-space--inner--top--120 section-space--inner--bottom--285">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="section-title-area text-center">
                            <h2 className="section-title mb-0">Why Choose us </h2>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="service-grid-slider-area">
                    <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                        <div className="service-slider">
                            <div className="service-slider__container service-slider__container--style2">
                                <Swiper {...params}>
                                    {DataList}
                                </Swiper>
                              
                            </div>
                        </div>
                        </div>
                       
                        {/* <Link style={{display: "flex", justifyContent:'center', alignItems:"center",}} to="/about-us" className='ht-btn--default ht-btn--default--dark-hover section-space--top--20'>Learn more</Link> */}
                     
                    </div>
                    </div>
                </div>
                {/*====================  End of project grid slider area  ====================*/}

            </div>
        )
    }
}


export default ServiceGridSlider;