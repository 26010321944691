import React, { Component } from 'react';
import Swiper from 'react-id-swiper';

class TestimonialSlider extends Component{
    render(){
        const params = {
            slidesPerView : 1,
            loop: true,
            speed: 1000,
            effect: 'fade',
            autoplay: {
                delay: 3000
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            renderPagenation: () => (
                <div className="swiper-pagination"></div>
              ),
        }
        let data = [
            {testimonialImage: "a.png", testimonialName: "Sarah Charles", testimonialDesignation: "Community Manager", testimonialText: "Liveeasy has revolutionized the way we manage our community. Their software property and HOA Community Management platform have simplified our administrative tasks, streamlined communication, and enhanced security. Thanks to Liveeasy, our residents are happier, and our operations run seamlessly."},
            {testimonialImage: "b.png", testimonialName: "Ayomide Uduak", testimonialDesignation: "HOA Board Memeber", testimonialText: "As a board member of our HOA, I highly recommend Liveeasy. Their personalized service and user-friendly interface have made a significant difference in our community. Maintenance requests were handled professionally. Liveeasy has exceeded our expectations in every aspect. It's the ultimate solution for modern community management."},
            {testimonialImage: "c.png", testimonialName: "Michael Thompson", testimonialDesignation: "Property Manager", testimonialText: "We used to struggle with manual processes and inefficient communication methods until we discovered Liveeasy. Their platform has brought convenience and efficiency to our estate management. It's a game-changer for any community."},
            {testimonialImage: "c.png", testimonialName: "Idris Eke", testimonialDesignation: "HOA President", testimonialText: "Choosing Liveeasy as our HOA management partner was the best decision we made. Their dedicated support team has been responsive and attentive to our needs, ensuring a smooth transition and ongoing assistance whenever required. With Liveeasy, we feel supported and empowered to effectively manage our community and meet the expectations of our residents."}
        ];

        let DataList = data.map((val, i)=>{
            return(
                <div className="swiper-slide testimonial-slider__single-slide" key={i}>
                    <div className="author">
                        {/* <div className="author__image">
                        <img src={`assets/img/testimonial/${val.testimonialImage}`} alt="" />
                        </div> */}
                        <div className="author__details">
                        <h4 className="name">{val.testimonialName}</h4>
                        <div className="designation">{val.testimonialDesignation}</div>
                        </div>
                    </div>
                    <div className="content">
                        {val.testimonialText}
                    </div>
                </div>
            )
        });
        
        return(
            <div>
                {/*====================  testimonial slider area ====================*/}
                <div className="testimonial-slider-area testimonial-slider-area-bg section-space--inner--120">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="testimonial-slider">
                        <div className="testimonial-slider__container-area">
                            <Swiper {...params}>
                                {DataList}
                            </Swiper>
                            <div className="swiper-pagination"></div>
                        </div>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
                {/*====================  End of testimonial slider area  ====================*/}

            </div>
        )
    }
}

export default TestimonialSlider;