import React, { Component } from "react";
import NavBar from "../components/NavBar";
import BrandLogoSlider from "../components/BrandLogoSlider";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
import "../assets/css/pricing.css";
import { Link } from "react-router-dom";
class Projects extends Component {
  render() {
    return (
      <div>
        {/* Navigation bar */}
        <NavBar />

        {/* breadcrumb */}
        {/*====================  breadcrumb area ====================*/}
        <div className="breadcrumb-area breadcrumb-bg">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="page-banner text-center">
                  <h1>Products</h1>
                  <ul className="page-breadcrumb">
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Products</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*====================  End of breadcrumb area  ====================*/}

        {/*====================  project details page content ====================*/}

        <div className="page-wrapper section-space--inner--60">
          {/*Projects section start*/}
          <div className="project-section">
            <div className="container">
              <div className="row">
                {/* <div className="col-12 section-space--bottom--40">
                  <div className="project-image">
                    <img
                      src="assets/img/projects/project-details.jpg"
                      className="img-fluid"
                      alt=""
                    />
                  </div>
                </div> */}
                <div className="col-lg-4 col-12 section-space--bottom--30">
                  <div className="project-information">
                    <h3>Product Features</h3>
                    <ul>
                      <li>
                        <strong>Estate Management</strong>
                      </li>
                      <li>
                        <strong>Community Securty</strong>
                      </li>
                      <li>
                        <strong>Community Forum</strong>
                      </li>
                      <li>
                        <strong>Community Administration</strong>
                      </li>
                      <li>
                        <strong>Resident Portal</strong>
                      </li>
                      <li>
                        <strong>Document Management:</strong>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-8 col-12 section-space--bottom--30 pl-30 pl-sm-15 pl-xs-15">
                  <div className="project-details">
                    <h2>Product Plans</h2>
                    <p>
                    At Liveeasy, we offer flexible subscription plans designed to meet the unique needs of Nigerian communities. Whether you're a small HOA or a large residential complex, our packages provide you with the power and functionality to efficiently manage your community. Our plans are competitively priced to offer you the best value for your money.
                    </p>
                    <p>
                    Each plan is specifically designed to address the needs and challenges faced by communities.  As your community grows, you can easily upgrade to a higher plan without any hassle. Take a look at our subscription plans and select the one that suits your requirements
                    </p> 
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*Projects section end*/}
        </div>

        {/* <div>
          <h3 className="text-center">
          Subscription Plans Tailored for Nigerian Communities
          </h3>
        

          <div class="pricing-table">
            

            <div class="pricing-item">
              <div class="pricing-title">Basic Package</div>
              <div class="pricing-value">
                &#8358; 10,000
                <span class="undertext">/user/mth</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">1 - 50 Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>

            <div class="pricing-item">
              <div class="pricing-title">Silver Package</div>
              <div class="pricing-value">
                &#8358; 22,500
                <span class="undertext">/user/mth</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">51 - 150 Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>

            <div class="pricing-item">
              <div class="pricing-title">Gold Package</div>
              <div class='selected'>Recommended</div>
              <div class="pricing-value">
                &#8358; 30,000
                <span class="undertext">/user/mth</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">151 - 300 Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>

            <div class="pricing-item">
              <div class="pricing-title">Diamond Package</div>
              <div class="pricing-value">
                
                <span class="undertext">Contact Customer Support</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">300 + Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-center">
          Subscription Plans Tailored for US Communities
          </h3>
        

          <div class="pricing-table">
            

            <div class="pricing-item pricing-featured">
              <div class="pricing-title">Basic Package</div>
              <div class="pricing-value">
              $150 
                <span class="undertext">/user/mth</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">1 - 50 Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>

            <div class="pricing-item pricing-featured">
              <div class="pricing-title">Silver Package</div>
              <div class="pricing-value">
              $375
                <span class="undertext">/user/mth</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">51 - 150 Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>

            <div class="pricing-item pricing-featured">
              <div class="pricing-title">Gold Package</div>
              <div class='selected'>Recommended</div>
              <div class="pricing-value">
              $600
                <span class="undertext">/user/mth</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">151 - 300 Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>

            <div class="pricing-item pricing-featured">
              <div class="pricing-title">Diamond Package</div>
              <div class="pricing-value">
                
                <span class="undertext">Contact Customer Support</span>
              </div>
              <ul class="pricing-features">
                <li>
                  <span class="keywords">300 + Users</span>
                </li>
                <li>
                  <span class="keywords">Visit Register</span>
                </li>
                <li>
                  <span class="keywords">Community Forum</span>
                </li>
                <li>
                  <span class="keywords">Ticketing</span>
                </li>
              </ul>
              <div class="button">Get Started</div>
            </div>
          </div>
        </div> */}

        {/*====================  End of project details page content  ====================*/}

        {/* Brand logo */}
        <BrandLogoSlider background="grey-bg" />

        {/* Footer */}
        <Footer />

        {/* Mobile Menu */}
        <MobileMenu />
      </div>
    );
  }
}

export default Projects;
