import React, { Component } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import "./assets/css/style.css";
import HomeTwo from "./HomeTwo";
import About from "./pages/About";
import Contact from "./pages/Contact";
import PageNotFound from "./pages/404";
import NoMAtch from "./pages/404";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import ProjectDetails from "./project/ProjectDetails";
import HttpsRedirect from "react-https-redirect";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ScrollToTop from "./components/ScrollToTop";

class Root extends Component {
  render() {
    return (
      <HttpsRedirect>
        <BrowserRouter>
        <ScrollToTop/>
          <Switch>
            <Route exact path="/" component={HomeTwo} />
            <Route exact path="/about-us" component={About} />
            <Route exact path="/product" component={ProjectDetails} />
            <Route exact path="/contact-us" component={Contact} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />

            <Route exact path="/404" component={PageNotFound} />
            <Route component={NoMAtch} />
          </Switch>
        </BrowserRouter>
      </HttpsRedirect>
    );
  }
}

ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.register();
